import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { alpha, Box } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import axios from 'axios'
import * as moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BiEditAlt, BiTrashAlt } from 'react-icons/bi'
import { useAuthContext } from '../hooks/useAuthContext'
import SideBar from '../layout/SideBar'

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    onSelectAllClick,
    headCells,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function EnhancedTableToolbar(props) {
  const { numSelected, handleDeleteAction2 } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        mt: 1,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Whats New
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={(e) => handleDeleteAction2(e)}>
            <BiTrashAlt color="red" />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

const PointAmounts = () => {
  const [data, setData] = useState([])
  const { dispatch } = useAuthContext()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [search, setSearch] = useState()
  const [openDelete, setOpenDelete] = useState(false)
  const [openDelete2, setOpenDelete2] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const headCells = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'actions',
      label: 'Actions',
    },
    {
      id: 'startingDate',
      label: 'Starting Date',
    },
    {
      id: 'endingDate',
      label: 'Ending Date',
    },
    {
      id: 'points',
      label: 'Point Amount',
    },
  ]

  const handleDeleteAction = (e, id) => {
    e.stopPropagation()
    setDeleteId(id)
    setOpenDelete(!openDelete)
  }

  const handleDeleteAction2 = (e) => {
    e.stopPropagation()
    setOpenDelete2(!openDelete2)
  }

  const handleClose = () => {
    setOpenDelete(!openDelete)
  }

  const handleClose2 = () => {
    setOpenDelete2(!openDelete2)
  }

  // Delete user
  const handleDeleteButton = () => {
    setLoading(true)
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/admin/coupons/offer-points/${deleteId}`,
      )
      .then((response) => {
        if (response.data) {
          setLoading(false)
          setOpenDelete(!openDelete)
          setSelected([])
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      })
  }

  // Delete user
  const handleDeleteButton2 = () => {
    setLoading2(true)
    selected?.map((id) =>
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/admin/coupons/offer-points/${id}`,
        )
        .then((response) => {
          if (response.data) {
            setLoading2(false)
            setOpenDelete2(!openDelete2)
            setSelected([])
          }
        })
        .catch((error) => {
          setLoading2(false)
          console.error(error)
        }),
    )
  }

  // set page title
  useEffect(() => {
    document.title = 'Offer Points | Wallnut'

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user')}`,
      },
    }

    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/admin/coupons/offer-points`,
          config,
        )
        .then((response) => {
          if (response.data) {
            setData(response.data.data)
          }
        })
        .catch((error) => {
          console.log(error)
          // remove user from storage
          localStorage.removeItem('user')

          // dispatch logout action
          dispatch({ type: 'LOGOUT' })
        })
    }

    getData()
  }, [dispatch, loading, loading2])

  const renderData = (
    <Box>
      <Dialog
        open={openDelete}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ padding: '1rem 1.5rem', fontWeight: 'bold' }}
          id="alert-dialog-title"
        >
          Delete
        </DialogTitle>
        <DialogContent sx={{ padding: '1rem 1.5rem' }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '1rem 1.5rem' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            loadingIndicator={<CircularProgress color="primary" size={25} />}
            variant="contained"
            color="error"
            onClick={handleDeleteButton}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete2}
        onClose={handleClose2}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ padding: '1rem 1.5rem', fontWeight: 'bold' }}
          id="alert-dialog-title"
        >
          Delete
        </DialogTitle>
        <DialogContent sx={{ padding: '1rem 1.5rem' }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete selected item(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '1rem 1.5rem' }}>
          <Button onClick={handleClose2}>Cancel</Button>
          <LoadingButton
            loading={loading2}
            loadingIndicator={<CircularProgress color="primary" size={25} />}
            variant="contained"
            color="error"
            onClick={handleDeleteButton2}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Paper sx={{ mb: 2 }}>
        <Box>
          <Grid container spacing={2} paddingX>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                label="Search"
                variant="outlined"
                fullWidth
                sx={{ maxWidth: '300px' }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                sx={{ float: 'right' }}
                size="small"
                href="/admin/points/create"
                variant="contained"
              >
                Add Point Offer
              </Button>
            </Grid>
          </Grid>
        </Box>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleDeleteAction2={handleDeleteAction2}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={headCells}
              numSelected={selected.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.sort(getComparator(order, orderBy)).slice() */}
              {data.length > 0 &&
                stableSort(data, getComparator(order, orderBy))
                  .filter(
                    (rows) =>
                      (rows && !search) ||
                      (rows.startDate + rows.endDate + rows.pointAmount)
                        .toString()
                        .toLowerCase()
                        .includes(search && search.toString().toLowerCase()),
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .reverse()
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {index + page * rowsPerPage + 1}
                        </TableCell>
                        <TableCell style={{ minWidth: '115px' }}>
                          <IconButton
                            aria-label="edit"
                            onClick={(e) => e.stopPropagation()}
                            href={`/admin/points/edit/${row.id}`}
                          >
                            <BiEditAlt color="green" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            // onClick={() => handleClickOpenDelete(row.id, table)}
                            onClick={(e) => handleDeleteAction(e, row.id)}
                          >
                            <BiTrashAlt color="red" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {moment(row.startDate).format('DD MMM, YYYY')}
                        </TableCell>
                        <TableCell>
                          {moment(row.endDate).format('DD MMM, YYYY')}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{ fontWeight: 'bold' }}
                            display="inline"
                          >
                            {row.pointAmount}₹
                          </Typography>
                          &nbsp;per point
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            15,
            25,
            50,
            100,
            {
              label: 'All',
              value: data.length,
            },
          ]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )

  return <SideBar title="Coupon points" page={renderData} />
}

export default PointAmounts
