import { Box } from '@mui/material'
import React from 'react'
import logo from '../resources/logo.svg'

const Loader = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="loader"></div>
      <img src={logo} alt="logo" width="50" />
      {/* <Typography sx={{ mt: 2 }}>Loading...</Typography> */}
    </Box>
  )
}

export default Loader
