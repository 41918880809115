import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const FourOhFour = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h1" fontWeight={600} color="primary">
        404
      </Typography>
      <Typography
        variant="body1"
        onClick={() => navigate('/admin')}
        sx={{ mt: 10, cursor: 'pointer' }}
      >
        ← Back to homepage
      </Typography>
    </Box>
  )
}

export default FourOhFour
