import './App.scss'
import { lazy, Suspense, useMemo } from 'react'
import { retry } from './utils/CommonFunctions'
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import Loader from './components/Loader'
import ScrollToTop from './components/ScrollToTop'
import PointAmounts from './pages/PointAmounts'
import { styled } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import FourOhFour from './pages/FourOhFour'

// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    background-color: #130146;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`

const Dashboard = lazy(() => retry(() => import('./pages/Dashboard.js')))
const UsersAll = lazy(() => retry(() => import('./pages/UsersAll.js')))
const Roles = lazy(() => retry(() => import('./pages/Roles.js')))
const UsersTransactions = lazy(() =>
  retry(() => import('./pages/UsersTransactions.js')),
)
const UsersToday = lazy(() => retry(() => import('./pages/UsersToday.js')))
const RedeemedByUser = lazy(() => retry(() => import('./pages/RedeemedByUser')))
const Categories = lazy(() => retry(() => import('./pages/Categories')))
const Login = lazy(() => retry(() => import('./pages/Login')))
const Products = lazy(() => retry(() => import('./pages/Products')))
const ProductInquiry = lazy(() => retry(() => import('./pages/ProductInquiry')))
const Downloads = lazy(() => retry(() => import('./pages/Downloads')))
const Videos = lazy(() => retry(() => import('./pages/Videos')))
const Events = lazy(() => retry(() => import('./pages/Events')))
const WhatsNew = lazy(() => retry(() => import('./pages/WhatsNew')))
const Transactions = lazy(() => retry(() => import('./pages/Transactions')))
const Coupons = lazy(() => retry(() => import('./pages/Coupons')))
const Faqs = lazy(() => retry(() => import('./pages/Faqs')))
const ChangePassword = lazy(() => retry(() => import('./pages/ChangePassword')))
const CategoryForm = lazy(() =>
  retry(() => import('./pages/forms/CategoryForm')),
)
const ProductForm = lazy(() => retry(() => import('./pages/forms/ProductForm')))
const DownloadForm = lazy(() =>
  retry(() => import('./pages/forms/DownloadForm')),
)

const VideoForm = lazy(() => retry(() => import('./pages/forms/VideoForm')))
const EventForm = lazy(() => retry(() => import('./pages/forms/EventForm')))
const WhatsNewForm = lazy(() =>
  retry(() => import('./pages/forms/WhatsNewForm')),
)
const PointsForm = lazy(() => retry(() => import('./pages/forms/PointsForm')))
const FaqForm = lazy(() => retry(() => import('./pages/forms/FaqForm')))
const CategoryUpdateForm = lazy(() =>
  retry(() => import('./pages/forms/CategoryUpdateForm')),
)
const ProductUpdateForm = lazy(() =>
  retry(() => import('./pages/forms/ProductUpdateForm')),
)
const DownloadUpdateForm = lazy(() =>
  retry(() => import('./pages/forms/DownloadUpdateForm')),
)
const VideoUpdateForm = lazy(() =>
  retry(() => import('./pages/forms/VideoUpdateForm')),
)
const EventUpdateForm = lazy(() =>
  retry(() => import('./pages/forms/EventUpdateForm')),
)
const WhatsNewUpdateForm = lazy(() =>
  retry(() => import('./pages/forms/WhatsNewUpdateForm1')),
)
const FaqUpdateForm = lazy(() =>
  retry(() => import('./pages/forms/FaqUpdateForm')),
)
const PointsUpdateForm = lazy(() =>
  retry(() => import('./pages/forms/PointsUpdateForm')),
)

const App = () => {
  const { user } = useAuthContext()

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          background: {
            default: '#f4f7ff',
          },
          primary: {
            main: '#1C9C41',
          },
          secondary: {
            main: '#FFFFFF',
            text: '#BBBBBB',
          },
        },
        typography: {
          fontFamily: 'Poppins, sans-serif',
          h4: {
            fontWeight: 'bold',
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: '36px',
                border: 'none',
                boxShadow: 'none',
              },
            },
          },
          MuiCardActionArea: {
            styleOverrides: {
              root: {
                borderRadius: '36px',
                border: 'none',
                boxShadow: 'none',
                padding: '0.5rem 0.2rem',
                height: '100%',
                '&:hover': {
                  background:
                    'linear-gradient(90deg, #1C9C41 0%, #A6CE39 100%)',
                  color: '#FFF',
                },
              },
            },
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                margin: '0.5rem 0.2rem',
                height: '100%',
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: '50px',
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                padding: '0 2px',
                color: 'rgba(91, 91, 91, 0.48)',
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                padding: '15px 20px',
                borderRadius: '50px',
                fontWeight: 'bold',
                textTransform: 'none',
                wordBreak: 'keep-all',
              },
              contained: {
                color: '#FFFFFF',
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                background: 'linear-gradient(90deg, #1C9C41 0%, #A6CE39 100%)',
                boxShadow: 'none',
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: '36px',
                border: 'none',
                boxShadow: 'none',
                padding: '20px 0',
              },
            },
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                margin: '0 20px',
              },
            },
          },
          MuiTableContainer: {
            styleOverrides: {
              root: {
                padding: '10px',
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              rounded: {
                borderRadius: '15px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                padding: '0.5rem',
                ':first-of-type': {
                  borderRadius: '15px 15px 0 0',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                },
                ':last-of-type': {
                  borderRadius: '0 0 15px 15px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                },
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              head: {
                fontWeight: 'bold',
              },
            },
          },
          MuiAlert: {
            styleOverrides: {
              root: {
                background: 'linear-gradient(90deg, #1C9C41 0%, #A6CE39 100%)',
                color: '#FFF',
              },
              icon: {
                '&.MuiAlert-icon': {
                  color: '#FFF',
                },
              },
            },
          },
        },
      }),
    [],
  )

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <StyledSnackbarProvider
          // iconVariant={{
          //   success: <img src={success} alt="success" />,
          //   error: <img src={error} alt="error" />,
          //   warning: '⚠️',
          //   info: 'ℹ️',
          // }}
          hideIconVariant
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                exact
                path="/admin/dashboard"
                element={
                  user ? <Dashboard /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/users"
                element={
                  user ? <UsersAll /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/roles"
                element={
                  user ? <Roles /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/users/transactions/:userId"
                element={
                  user ? (
                    <UsersTransactions />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/dashboard/users-today"
                element={
                  user ? <UsersToday /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/dashboard/redeemed-by-user"
                element={
                  user ? (
                    <RedeemedByUser />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/categories"
                element={
                  user ? <Categories /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/products"
                element={
                  user ? <Products /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/product-inquiry"
                element={
                  user ? (
                    <ProductInquiry />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/downloads"
                element={
                  user ? <Downloads /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/videos"
                element={
                  user ? <Videos /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/events"
                element={
                  user ? <Events /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/whats-new"
                element={
                  user ? <WhatsNew /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/transactions"
                element={
                  user ? (
                    <Transactions />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/coupons"
                element={
                  user ? <Coupons /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/points"
                element={
                  user ? (
                    <PointAmounts />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/faqs"
                element={
                  user ? <Faqs /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/change-password"
                element={
                  user ? (
                    <ChangePassword />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/categories/create"
                element={
                  user ? (
                    <CategoryForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/products/create"
                element={
                  user ? (
                    <ProductForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/downloads/create"
                element={
                  user ? (
                    <DownloadForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/videos/create"
                element={
                  user ? <VideoForm /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/events/create"
                element={
                  user ? <EventForm /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/whats-new/create"
                element={
                  user ? (
                    <WhatsNewForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/points/create"
                element={
                  user ? <PointsForm /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/faqs/create"
                element={
                  user ? <FaqForm /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/categories/edit/:id"
                element={
                  user ? (
                    <CategoryUpdateForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/products/edit/:id"
                element={
                  user ? (
                    <ProductUpdateForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/downloads/edit/:id"
                element={
                  user ? (
                    <DownloadUpdateForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/videos/edit/:id"
                element={
                  user ? (
                    <VideoUpdateForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/events/edit/:id"
                element={
                  user ? (
                    <EventUpdateForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/whats-new/edit/:id"
                element={
                  user ? (
                    <WhatsNewUpdateForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/faqs/edit/:id"
                element={
                  user ? (
                    <FaqUpdateForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/points/edit/:id"
                element={
                  user ? (
                    <PointsUpdateForm />
                  ) : (
                    <Navigate replace to="/admin/login" />
                  )
                }
              />
              <Route
                exact
                path="/admin/login"
                element={
                  !user ? <Login /> : <Navigate replace to="/admin/dashboard" />
                }
              />
              <Route
                exact
                path="/"
                element={<Navigate replace to="/admin/login" />}
              />
              <Route
                exact
                path="/admin"
                element={<Navigate replace to="/admin/login" />}
              />
              <Route
                exact
                path="/admin/*"
                element={<FourOhFour replace to="/admin/login" />}
              />
            </Routes>
          </Suspense>
        </StyledSnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
