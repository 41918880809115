import { useState, forwardRef, useCallback, useEffect } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  CircularProgress,
} from '@mui/material'
import {
  BiMenu,
  BiLogOut,
  BiCategory,
  BiCollection,
  BiDownload,
  BiFilm,
  BiCalendarEvent,
  BiQrScan,
  BiBell,
  BiHome,
  BiInfoCircle,
  BiBarcodeReader,
  BiKey,
  BiUser,
  BiMoney,
} from 'react-icons/bi'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
import { useLogout } from '../hooks/useLogout'
import { Container } from '@mui/system'
import logo from '../resources/logo.svg'
import logo2 from '../resources/logo.png'
import axios from 'axios'
import whatsNewIcon from '../resources/whats-new.svg'
import { LuUsers } from 'react-icons/lu'

const drawerWidth = 270

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SideBar = (props) => {
  const { window, title, page } = props
  const { pathname } = useLocation()
  const { logout } = useLogout()
  const [isLoading, setLoading] = useState()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [openLogout, setOpenLogout] = useState(false)
  const [notification, setNotification] = useState(false)
  const navigate = useNavigate()

  const handleClickOpenLogout = () => {
    setOpenLogout(true)
  }

  const handleCloseLogout = () => {
    setOpenLogout(false)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleLogout = () => {
    setLoading(true)
    logout()
    setLoading(false)
  }

  const handleNotificationClick = useCallback(() => {
    setNotification(false)
    navigate('/admin/product-inquiry')
  }, [navigate])

  const drawer = (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          my: 2,
        }}
      >
        <img src={logo} alt="logo" width="50" />
        <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
          Wallnut Admin
        </Typography>
      </Box>
      <Divider />
      <List
        sx={{
          padding: 1,
          // selected and (selected + hover) states
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgba(28, 156, 65, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(28, 156, 65, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(48%) sepia(21%) saturate(1742%) hue-rotate(85deg) brightness(94%) contrast(85%)',
            },
          },
          // hover states
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'rgba(28, 156, 65, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(28, 156, 65, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(48%) sepia(21%) saturate(1742%) hue-rotate(85deg) brightness(94%) contrast(85%)',
            },
          },
        }}
      >
        {[
          {
            text: 'Dashboard',
            link: '/admin/dashboard',
            icon: <BiHome size={28} color="black" />,
          },
          {
            text: 'Users',
            link: '/admin/users',
            icon: <BiUser size={28} color="black" />,
          },
          {
            text: 'Roles',
            link: '/admin/roles',
            icon: <LuUsers size={28} color="black" />,
          },
        ].map((item, index) => (
          <StyledLink to={item.link} key={index} onClick={handleDrawerToggle}>
            <ListItem
              selected={item.link === pathname.split('/', 3).join('/')}
              sx={{ mt: 1 }}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.link === '/admin/product-inquiry' && notification && (
                  <span
                    className="dot"
                    style={{
                      position: 'absolute',
                      right: '4px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  ></span>
                )}
              </ListItemButton>
            </ListItem>
          </StyledLink>
        ))}
      </List>

      <Divider />
      <List
        sx={{
          padding: 1,
          // selected and (selected + hover) states
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgba(28, 156, 65, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(28, 156, 65, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(48%) sepia(21%) saturate(1742%) hue-rotate(85deg) brightness(94%) contrast(85%)',
            },
          },
          // hover states
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'rgba(28, 156, 65, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(28, 156, 65, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(48%) sepia(21%) saturate(1742%) hue-rotate(85deg) brightness(94%) contrast(85%)',
            },
          },
        }}
      >
        {[
          {
            text: 'Categories',
            link: '/admin/categories',
            icon: <BiCategory size={28} color="black" />,
          },
          {
            text: 'Products',
            link: '/admin/products',
            icon: <BiCollection size={28} color="black" />,
          },
          {
            text: 'Product Inquiry',
            link: '/admin/product-inquiry',
            icon: <BiBell size={28} color="black" />,
          },
          {
            text: 'Downloads',
            link: '/admin/downloads',
            icon: <BiDownload size={28} color="black" />,
          },
          {
            text: 'Videos',
            link: '/admin/videos',
            icon: <BiFilm size={28} color="black" />,
          },
          {
            text: 'Events',
            link: '/admin/events',
            icon: <BiCalendarEvent size={28} color="black" />,
          },
          {
            text: "What's New",
            link: '/admin/whats-new',
            icon: (
              <img src={whatsNewIcon} alt="whatsnew" size={28} color="black" />
            ),
          },
          {
            text: 'Transactions',
            link: '/admin/transactions',
            icon: <BiMoney size={28} color="black" />,
          },
          {
            text: 'Coupons',
            link: '/admin/coupons',
            icon: <BiQrScan size={28} color="black" />,
          },
          {
            text: 'Coupon Points',
            link: '/admin/points',
            icon: <BiBarcodeReader size={28} color="black" />,
          },
          {
            text: 'FAQs',
            link: '/admin/faqs',
            icon: <BiInfoCircle size={28} color="black" />,
          },
        ].map((item, index) => (
          <StyledLink to={item.link} key={index} onClick={handleDrawerToggle}>
            <ListItem
              selected={item.link === pathname.split('/', 3).join('/')}
              sx={{ mt: 1 }}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.link === '/admin/product-inquiry' && notification && (
                  <span
                    className="dot"
                    style={{
                      position: 'absolute',
                      right: '4px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  ></span>
                )}
              </ListItemButton>
            </ListItem>
          </StyledLink>
        ))}
      </List>
      <Divider />
      <List
        sx={{
          padding: 1,
          // selected and (selected + hover) states
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgba(28, 156, 65, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(28, 156, 65, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(48%) sepia(21%) saturate(1742%) hue-rotate(85deg) brightness(94%) contrast(85%)',
            },
          },
          // hover states
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'rgba(28, 156, 65, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(28, 156, 65, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(48%) sepia(21%) saturate(1742%) hue-rotate(85deg) brightness(94%) contrast(85%)',
            },
          },
        }}
      >
        {['Change Password'].map((text, index) => (
          <StyledLink
            to="/admin/change-password"
            key={index}
            onClick={handleDrawerToggle}
          >
            <ListItem
              selected={
                '/admin/change-password' === pathname.split('/', 3).join('/')
              }
              sx={{ mt: 1 }}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <BiKey size={28} color="black" />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          </StyledLink>
        ))}
        {['Logout'].map((text) => (
          <ListItem
            sx={{ mt: 1 }}
            key={text}
            disablePadding
            onClick={handleClickOpenLogout}
          >
            <ListItemButton>
              <ListItemIcon>
                <BiLogOut size={28} color="black" />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={openLogout}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseLogout}
        aria-describedby="logout-dialog"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog">
            Are you sure you want to Logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseLogout}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleLogout}
            loading={isLoading}
            loadingIndicator={<CircularProgress color="secondary" size={25} />}
          >
            Logout
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )

  useEffect(() => {
    const getProductInquiryData = async () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/products/product/inquiry`)
        .then((response) => {
          setNotification(
            response.data?.data[0]?.isRead !== undefined
              ? !response.data?.data[0]?.isRead
              : false,
          )
        })
        .catch((error) => {
          console.error(error)
        })
    }

    getProductInquiryData()
  }, [])

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          zIndex: 100,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <BiMenu />
          </IconButton>
          <Box className="display" sx={{ display: { sm: 'none' } }}>
            <img src={logo2} alt="logo" width="50" />
            <Typography fontWeight="bold" marginLeft={1}>
              WALLNUT ADMIN
            </Typography>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="hide"
            sx={{ fontWeight: 'bold', display: { sm: 'block' } }}
          >
            {title}
          </Typography>
          <IconButton
            color="inherit"
            edge="end"
            sx={{ ml: 'auto', mr: { md: 2, xs: 0 }, position: 'relative' }}
            onClick={handleNotificationClick}
          >
            {notification && (
              <span
                className="dot"
                style={{ position: 'absolute', right: '4px', top: '4px' }}
              ></span>
            )}
            <BiBell />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
            zIndex: 100,
            position: 'relative',
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
          {page}
        </Container>
      </Box>
    </Box>
  )
}

SideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default SideBar
